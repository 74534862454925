<template>
  <v-container
    fluid
    style="height: 100%; display: flex; flex-direction: column; background: #eceff1; height: 100%"
    class="px-6 pt-2"
    v-if="manufacturer != null"
  >
    <v-row class="my-0" justify="center" align="center">
      <v-col>
        <h3>
          List of equipment that are manufactured by: <b>{{ manufacturer.name }}</b>
        </h3>
      </v-col>
      <v-col
        lg="5"
        md="2"
        sm="6"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          hide-details
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      class="elevation-2 companies-table table-sticky-header-exclude-footer"
      :headers="headers"
      :items="entities"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      :options.sync="options"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip right z-index="999" nudge-right="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" :class="'equipment-image-' + item.id">
              <div class="equipment-img">
                <div
                  style="height: 100%; width: 100%"
                  v-viewer
                  @click.stop
                  v-if="item.imageUrl != null && item.imageUrl != ''"
                >
                  <img :key="item.id + '_img'" :src="item.imageUrl" height="100%" width="100%" />
                </div>
                <i v-else-if="item.category != null" :class="'fad ' + item.category.icon"></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item.imageUrl != null) & (item.imageUrl != '')"
              class="img"
              :src="item.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else-if="item.category != null"
              :class="'fad ' + item.category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
            <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.category`]="{ item }">
        <v-row no-gutters>
          <v-col sm="auto" class="d-flex justify-content-start align-items-center">
            <i v-if="item.category != null" :class="'equipment-icon fad ' + item.category.icon"></i>
            <i v-else :class="'equipment-icon fad fa-plug'"></i>
          </v-col>
          <v-col class="d-flex align-center">
            <span class="category-name" v-if="item.category">{{ item.category.name }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.tag`]="{ item }">
        <div class="equipment-tag font-weight-bold">{{ item.tag }}</div>
      </template>

      <template v-slot:[`item.manufacture.name`]="{ item }">
        <div v-if="item.manufacture != null" class="d-flex align-center" style="gap: 0.5rem">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo-mini">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="item.manufacture.logoUrl != null && item.manufacture.logoUrl != ''"
                  >
                    <img
                      :key="item.manufacture.id + '_img'"
                      :src="item.manufacture.logoUrl"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.manufacture.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="item.manufacture.logoUrl != null && item.manufacture.logoUrl != ''"
                :src="item.manufacture.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h5 class="ma-0">{{ item.manufacture.name }}</h5>
        </div>
        <div v-else class="text--disabled">N/A</div>
      </template>

      <template v-slot:[`item.productFamily.name`]="{ item }">
        <h5 class="ma-0" v-if="item.productFamily != null">
          {{ item.productFamily.name }}
        </h5>
        <div v-else class="text--disabled">N/A</div>
      </template>

      <template v-slot:[`item.model`]="{ item }">
        <div class="equipment-model mono-font font-weight-bold">{{ item.model }}</div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="secondary" icon elevation="0" @click="viewEquipment(item)">
          <i class="fad fa-info"></i>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <edit-equipment ref="viewEquipment" nested></edit-equipment>
  </v-container>
</template>

<script>
import equipmentHeader from "../../../../Equipments/config/tables/equipmentSummaryAction.header";
import manufacturerService from "../../services/manufacturers-service";
import EditEquipment from "../../../../Equipments/components/EditEquipment.vue";

export default {
  components: { EditEquipment },
  name: "manufacturer-has-equipment",
  props: {
    manufacturer: {
      type: Object,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    storageKey: {
      type: String,
    },
  },
  data() {
    return {
      entities: [],
      total: 0,
      clientsTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        page: 1,
        itemsPerPage: 15,
      },
      loadingStates: {
        table: false,
      },
      headers: equipmentHeader,
    };
  },
  created() {
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  methods: {
    viewEquipment(equipmentObj) {
      this.$refs.viewEquipment.open(equipmentObj.id, false);
    },
    getEquipmentItems() {
      this.$backToTop(
        0,
        document.querySelector(".manufacturer-stats-tabs.v-window.v-item-group.v-tabs-items")
      );
      this.loadingStates.table = true;
      if (this.manufacturer) {
        manufacturerService
          .getManufacturerStatsItems(this.manufacturer.id, this.$clean(this.options), "equipment")
          .then((resp) => {
            this.entities = resp.data.items;
            this.total = resp.data.total;
            this.loadingStates.table = false;
          })
          .catch(() => {
            this.loadingStates.table = false;
          });
      }
    },
    getProjectDebounced() {
      if (this.clientsTimerId == null) {
        this.clientsTimerId = -1;
        this.getEquipmentItems();
        return;
      }
      // cancel pending call
      clearTimeout(this.clientsTimerId);

      // delay new call 400ms
      this.clientsTimerId = setTimeout(() => {
        this.getEquipmentItems();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectDebounced();
      },
      deep: true,
    },
    active(val) {
      if (val) {
        this.options.page = 1;
        this.getProjectDebounced();
      } else {
        this.entities = [];
        this.total = 0;
      }
    },
  },
};
</script>
