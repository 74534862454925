<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '1200px'"
    :min-size="nested ? 400 : 1200"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.stats.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small v-if="manufacturer != null">
        <span>
          <i class="fad fa-pen-square mr-2"></i>Manufacturer Stats:
          <b>{{ manufacturer.name }}</b>
        </span>
      </h3>
      <v-btn @click="slideouts.stats.active = false" icon><i class="far fa-times"></i></v-btn>
    </template>

    <div color="white" style="position: sticky; top: 0; z-index: 1">
      <v-tabs v-model="slideouts.tab" color="error">
        <v-tab :key="0">
          Equipment
          <v-chip
            :color="slideouts.tab == 0 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ equipmentCount }}
          </v-chip>
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-tabs-items v-model="slideouts.tab" class="manufacturer-stats-tabs">
      <v-tab-item :key="0">
        <manufacturer-has-equipment
          :manufacturer="manufacturer"
          :active="slideouts.stats.active"
          :storageKey="storageKey"
        ></manufacturer-has-equipment>
      </v-tab-item>
    </v-tabs-items>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="slideouts.stats.active = false">
          <i class="fal fa-times mr-2"></i>
          Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import manufacturerService from "../../services/manufacturers-service";
import ManufacturerHasEquipment from "./ManufacturerHasEquipment.vue";

export default {
  components: {
    ManufacturerHasEquipment,
  },
  name: "manufacturer-stats",
  data() {
    return {
      storageKey: "ManufacturerStats",
      manufacturer: null,
      equipmentCount: 0,
      slideouts: {
        tab: 0,
        stats: {
          active: false,
          isLoading: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.stats.active);
      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    getManufacturerStats() {
      if (this.manufacturer) {
        manufacturerService.getManufacturerStats(this.manufacturer.id).then((resp) => {
          this.equipmentCount = resp.data.equipmentsCount;
        });
      }
    },
    open(manufacturer) {
      this.manufacturer = manufacturer;
      this.getManufacturerStats();
      this.slideouts.stats.active = true;
      this.slideouts.tab = 0;
    },
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}
.equipment-stats-tabs.v-window.v-item-group.v-tabs-items {
  overflow: auto;
}

.manufacturer-stats-tabs {
  .v-window__container {
    height: 100%;
    .v-window-item {
      height: 100%;
    }
  }
}
</style>
